import React from 'react';

import {Card} from '@mui/material';
import {
  DataGrid as MuiDataGrid,
  GridColDef,
  DataGridProps,
  ptBR,
} from '@mui/x-data-grid';

interface IProps extends DataGridProps {
  columns: GridColDef[];
  rows: any[];
  initialPageSize?: number;
  checkboxSelection?: boolean;
}

export const DataGrid: React.FC<IProps> = ({
  rows,
  columns,
  checkboxSelection = false,
  ...rest
}) => {
  return (
    <Card elevation={1}>
      <MuiDataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        pagination
        checkboxSelection={checkboxSelection}
        autoHeight
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        {...rest}
      />
    </Card>
  );
};
