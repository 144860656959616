import React, {useState} from 'react';

import {Grid, MenuItem, Stack, Typography} from '@mui/material';
import {useMutation} from '@tanstack/react-query';
import {
  Button,
  Checkbox,
  NumberFormat,
  Select,
  TextField,
} from 'shared/components';
import {SHIPPING} from 'shared/constants';
import {useCart, useOrder} from 'shared/hooks';
import {locationsService} from 'shared/services/api/locationsService';

import {MenuItemShipping} from './MenuItemShipping';

export const Delivery: React.FC = () => {
  const [zipCode, setZipCode] = useState('');

  const {totalPrice} = useCart();
  const {address, setAddress, resetAddress} = useOrder();

  const getZipConde = useMutation(
    (data: string) => locationsService.getZipConde(data),
    {
      onSuccess: (data) => {
        const addressData = {
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf,
          zipCode: data.cep,
        };

        setAddress(addressData);

        localStorage.setItem('sodoma@address', JSON.stringify(addressData));
      },
    },
  );

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" textTransform="uppercase">
          Entrega
        </Typography>
      </Grid>

      {address.zipCode ? (
        <>
          <Grid item xs={12}>
            <Select name="shipping" label="Tipo de Entrega">
              {SHIPPING.map((item) => (
                <MenuItem value={item.shippingId} key={item.shippingId}>
                  <MenuItemShipping
                    name={item.name}
                    totalPrice={totalPrice}
                    shippingId={item.shippingId}
                    shippingDate={item.shippingDate}
                    shippingPrice={item.shippingPrice}
                  />
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" textTransform="uppercase">
              Dados do destinatário
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Stack gap={2}>
              <TextField name="full_name" label="Nome completo" />
              <TextField name="phone" label="Telefone" />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" textTransform="uppercase">
              Endereço do destinatário
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction={{sx: 'column', sm: 'row'}}
              gap={2}
              justifyContent="space-between"
              alignItems={{sx: 'flex-end', sm: 'center'}}>
              <Stack>
                <Typography variant="body2">
                  {address.street}, {address.neighborhood}{' '}
                  <b>CEP {address.zipCode}</b>
                </Typography>
                <Typography variant="body2">
                  {address.city} - {address.state}
                </Typography>
              </Stack>

              <Button
                label="Alterar CEP"
                color="primary"
                onClick={resetAddress}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField name="number" label="Número" />
          </Grid>

          <Grid item xs={12} sm={8}>
            <TextField name="complement" label="Complemento" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" textTransform="uppercase">
              Dados de cobrança
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <NumberFormat
              fullWidth
              name="cpf_cnpj"
              label="CPF ou CNPJ"
              prefix=""
              format="###.###.###-##"
              placeholder="000.000.000-00"
            />

            <Checkbox
              name="same_info"
              label="Minhas informações de cobrança e entrega são as mesmas"
            />
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction={{sx: 'column', sm: 'row'}}
              justifyContent="flex-end">
              <Button type="submit" label="Continuar" color="primary" />
            </Stack>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <NumberFormat
              fullWidth
              name="zipCode"
              label="CEP"
              prefix=""
              format="#####-###"
              placeholder="00000-000"
              onChange={({target}) => setZipCode(target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                label="Buscar CEP"
                color="primary"
                loading={getZipConde.isLoading}
                onClick={() => getZipConde.mutate(zipCode)}
                disabled={zipCode.replace(/\D/gim, '').trim().length !== 8}
              />
            </Stack>
          </Grid>
        </>
      )}
    </>
  );
};
