import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface IContextCart {
  cartItems: ICartItems[];
  addItem: (item: ICartItems) => void;
  removeItem: (id: number) => void;
  deleteItem: (id: number) => void;
  clearCart: () => void;
  quantity: number;
  totalPrice: number;
}

export const CartContext = createContext<IContextCart>({} as IContextCart);

export interface ICartItems {
  id: number;
  name: string;
  image: string;
  price: number;
  quantity: number;
}

interface IProps {
  children: React.ReactNode;
}

export const CartProvider: React.FC<IProps> = ({children}) => {
  const [cartItems, setCartItems] = useState<ICartItems[]>([]);

  useEffect(() => {
    const cardItemsStorage = localStorage.getItem('sodoma@cartItems');
    if (cardItemsStorage) setCartItems(JSON.parse(cardItemsStorage));
  }, []);

  const handleAddItem = useCallback((cartItem: ICartItems) => {
    setCartItems((state) => {
      if (state.find((item) => item.id === cartItem.id)) {
        const aux = state.map((item) =>
          item.id === cartItem.id
            ? {...item, quantity: item.quantity + 1}
            : item,
        );
        localStorage.setItem('sodoma@cartItems', JSON.stringify(aux));
        return aux;
      } else {
        localStorage.setItem(
          'sodoma@cartItems',
          JSON.stringify([...state, cartItem]),
        );
        return [...state, cartItem];
      }
    });
  }, []);

  const handleRemoveItem = useCallback((id: number) => {
    setCartItems((state) => {
      const aux = state.map((item) =>
        item.id === id ? {...item, quantity: item.quantity - 1} : item,
      );
      localStorage.setItem('sodoma@cartItems', JSON.stringify(aux));
      return aux;
    });
  }, []);

  const handleDeleteItem = useCallback((id: number) => {
    setCartItems((state) => {
      const aux = state.filter((item) => item.id !== id);
      localStorage.setItem('sodoma@cartItems', JSON.stringify(aux));
      return aux;
    });
  }, []);

  const handleClearCart = useCallback(() => {
    setCartItems([]);
    localStorage.removeItem('sodoma@cartItems');
  }, []);

  const quantity = useMemo(
    () => cartItems.reduce((acc, item) => acc + item.quantity, 0),
    [cartItems],
  );

  const totalPrice = useMemo(
    () => cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0),
    [cartItems],
  );

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addItem: handleAddItem,
        removeItem: handleRemoveItem,
        clearCart: handleClearCart,
        deleteItem: handleDeleteItem,
        quantity,
        totalPrice,
      }}>
      {children}
    </CartContext.Provider>
  );
};
