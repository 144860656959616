import React from 'react';

import {
  EmailOutlined,
  LocalShippingOutlined,
  PlaceOutlined,
} from '@mui/icons-material';
import {Card, Divider, Grid, Stack, Typography} from '@mui/material';
import {Button} from 'shared/components';
import {IShipping} from 'shared/constants';
import {useAuth, useOrder} from 'shared/hooks';

interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  shippingInfo: IShipping;
}

export const DeliverySummary: React.FC<IProps> = ({setStep, shippingInfo}) => {
  const {user, authenticated} = useAuth();
  const {address} = useOrder();

  const handleClick = () => {
    setStep(0);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" textTransform="uppercase">
          Resumo da entrega
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Card variant="outlined">
          <Stack paddingX={1} paddingY={0.5}>
            {authenticated && (
              <>
                <Stack direction="row" gap={2} p={1.5} alignItems="center">
                  <Stack>
                    <EmailOutlined fontSize="large" />
                  </Stack>
                  {user.email}
                </Stack>

                <Divider />
              </>
            )}

            <Stack direction="row" gap={2} p={1.5}>
              <Stack>
                <PlaceOutlined fontSize="large" />
              </Stack>

              <Stack direction="row" flex={1} justifyContent="space-between">
                <Stack>
                  <Typography variant="body2" fontWeight={600}>
                    Rua: {address?.street}
                  </Typography>
                  <Typography variant="body2" fontWeight={600}>
                    Numero: {address?.number}
                  </Typography>
                  <Typography variant="body2" fontWeight={600}>
                    Bairro: {address?.neighborhood}
                  </Typography>
                  <Typography variant="body2" fontWeight={600}>
                    Cidade: {address?.city}
                  </Typography>
                </Stack>

                <Button label="Alterar" variant="text" onClick={handleClick} />
              </Stack>
            </Stack>

            <Divider />

            <Stack direction="row" gap={2} p={1.5}>
              <Stack>
                <LocalShippingOutlined fontSize="large" />
              </Stack>

              <Stack direction="row" flex={1} justifyContent="space-between">
                <Stack>
                  <Typography variant="body2" fontWeight={600}>
                    Tipo de entrega: {shippingInfo.name}
                  </Typography>
                  <Typography variant="body2" fontWeight={600}>
                    Prazo de entrega: {shippingInfo.shippingDate}
                  </Typography>
                  <Typography variant="body2" fontWeight={600}>
                    Valor do frete:{' '}
                    {shippingInfo.shippingPrice === 0
                      ? 'Grátis'
                      : shippingInfo.shippingPrice}
                  </Typography>
                </Stack>

                <Button label="Alterar" variant="text" onClick={handleClick} />
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );
};
