import {CONTACTS, IShipping} from 'shared/constants';
import {IAddress, IOrder} from 'shared/context/Order';
import {moneyMask} from 'shared/utils/mask';

import {User} from './authService';

interface IOrderToWhatsapp {
  full_name: string;
  order: IOrder;
  user: User;
  address: IAddress;
  totalPrice: number;
  shipping: IShipping;
}

const checkOut = (props: IOrderToWhatsapp) => {
  const phone = CONTACTS.whatsapp;
  const baseUrl = `https://api.whatsapp.com/send?phone=55${phone}&text=`;
  const lineBreak = '%0A';

  const link = document.createElement('a');
  link.href = `${baseUrl}Olá, meu nome é ${
    props.user.full_name ?? props.full_name
  } gostaria de fazer o pedido:${lineBreak}${lineBreak}${props.order?.items
    ?.map((item) => `${item.quantity}x ${item.name} - ${moneyMask(item.price)}`)
    .join(`${lineBreak}`)}${lineBreak}${lineBreak}Total: ${moneyMask(
    props.totalPrice,
  )}${lineBreak}${lineBreak}Entregar em:${lineBreak}${props.address.street}, ${
    props.address.number ?? 'S/N'
  }${lineBreak}${
    props.address.complement ? `${props.address.complement}${lineBreak}` : ''
  }${props.address.neighborhood}${lineBreak}${props.address.city} - ${
    props.address.state
  }${lineBreak}${props.address.zipCode}${lineBreak}${lineBreak}Entrega: ${
    props.shipping.name
  }${lineBreak}Valor: ${
    props.shipping.shippingPrice === 0 ? 'Grátis' : props.shipping.shippingPrice
  }${lineBreak}Prazo: ${props.shipping.shippingDate}${
    props.order.commentary
      ? `${lineBreak}${lineBreak}Meu comentário: ${props.order.commentary}`
      : ''
  }
  ${lineBreak}${lineBreak}Obrigado!`;

  link.target = '_blank';
  link.click();
};

export const orderService = {checkOut};
