import React, {createContext, useCallback, useState} from 'react';

import {
  ThemeProvider as MuiThemeProvider,
  Theme,
  CssBaseline,
} from '@mui/material';
import {light, dark} from 'styles/theme';

export interface IContextTheme {
  toggleTheme(): void;
}

export const ThemeContext = createContext<IContextTheme>({} as IContextTheme);

interface IProps {
  children: React.ReactNode;
}

export const ThemeProvider: React.FC<IProps> = ({children}) => {
  const [theme, setTheme] = useState<Theme>(light);
  const handleToggleTheme = useCallback(() => {
    if (theme === light) setTheme(dark);
    else setTheme(light);
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        toggleTheme: handleToggleTheme,
      }}>
      <MuiThemeProvider theme={theme}>
        {children}
        <CssBaseline />
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
