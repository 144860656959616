import React from 'react';

import {Paper, Stack, Typography} from '@mui/material';
import {ICartItems} from 'shared/context/Cart';
import {moneyMask} from 'shared/utils/mask';

interface IProps {
  product: ICartItems;
}

export const CartItem: React.FC<IProps> = ({product}) => {
  return (
    <Stack
      direction="row"
      p={2}
      spacing={1}
      justifyContent="space-between"
      alignItems="center">
      <Stack p={0.5}>
        <Paper
          component="img"
          src={product.image}
          alt={product.name}
          width={60}
        />
      </Stack>

      <Stack spacing={1}>
        <Stack
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography
            fontSize={12}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}>
            {product.name}
          </Typography>

          <Typography fontSize={14} fontWeight={800}>
            {moneyMask(product.price * product.quantity)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
