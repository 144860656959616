import React from 'react';

import {AddRounded, DeleteRounded, RemoveRounded} from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import {ICartItems} from 'shared/context/Cart';
import {useCart} from 'shared/hooks';
import {moneyMask} from 'shared/utils/mask';

interface IProps {
  product: ICartItems;
}

export const CartItem: React.FC<IProps> = ({product}) => {
  const {addItem, removeItem, deleteItem} = useCart();

  return (
    <>
      <Stack
        direction="row"
        p={2}
        spacing={1}
        justifyContent="space-between"
        alignItems="center">
        <Stack p={0.5}>
          <Paper
            component="img"
            src={product.image}
            alt={product.name}
            width={75}
          />
        </Stack>

        <Stack spacing={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography
              fontSize={12}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}>
              {product.name}
            </Typography>

            <IconButton onClick={() => deleteItem(product.id)} color="primary">
              <DeleteRounded />
            </IconButton>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <ButtonGroup size="small" color="primary">
              <Button
                size="small"
                variant="contained"
                onClick={() => removeItem(product.id)}
                disabled={product.quantity === 1}>
                <RemoveRounded />
              </Button>

              <Button size="small" variant="contained">
                {product.quantity}
              </Button>

              <Button
                size="small"
                variant="contained"
                onClick={() => addItem(product)}>
                <AddRounded />
              </Button>
            </ButtonGroup>

            <Typography fontSize={20} fontWeight={800}>
              {moneyMask(product.price * product.quantity)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Divider variant="middle" />
    </>
  );
};
