import React from 'react';

import {Typography} from '@mui/material';

interface IProps {
  text: string;
  link: string;
  icon?: React.ReactNode;
}

export const FooterExternalLink: React.FC<IProps> = ({text, link, icon}) => {
  return (
    <Typography
      component="a"
      variant="body2"
      fontWeight={500}
      color="ButtonText"
      href={link}
      target="_blank"
      sx={{
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        ':hover': {color: 'primary.light'},
      }}>
      {icon} {text}
    </Typography>
  );
};
