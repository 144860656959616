import React, {createContext, useEffect, useState} from 'react';

import {ICartItems} from './Cart';

export interface IContextOrder {
  order: IOrder;
  setOrder: React.Dispatch<React.SetStateAction<IOrder>>;
  address: IAddress;
  setAddress: React.Dispatch<React.SetStateAction<IAddress>>;
  resetAddress: () => void;
}

export const OrderContext = createContext<IContextOrder>({} as IContextOrder);

interface IProps {
  children: React.ReactNode;
}

export interface IAddress {
  street: string;
  neighborhood: string;
  city: string;
  state: string;
  zipCode: string;
  number?: string;
  complement?: string;
}

const resetAddress = {
  street: '',
  neighborhood: '',
  city: '',
  state: '',
  zipCode: '',
};

export interface IOrder {
  user_id: string;
  commentary: string;
  newsletter: boolean;
  same_info: boolean;
  cpf_cnpj?: string;
  items?: ICartItems[];
}

export interface IShipping {
  id: string;
  name: string;
  price: number;
}

export const OrderProvider: React.FC<IProps> = ({children}) => {
  const [address, setAddress] = useState<IAddress>(resetAddress);
  const [order, setOrder] = useState<IOrder>({
    user_id: '',
    commentary: '',
    newsletter: false,
    same_info: true,
    cpf_cnpj: '',
  });

  useEffect(() => {
    const addressStorage = localStorage.getItem('sodoma@address');
    if (addressStorage) setAddress(JSON.parse(addressStorage));
  }, []);

  const handleResetAddress = () => {
    setAddress(resetAddress);
    localStorage.removeItem('sodoma@address');
  };

  return (
    <OrderContext.Provider
      value={{
        order,
        setOrder,
        address,
        setAddress,
        resetAddress: handleResetAddress,
      }}>
      {children}
    </OrderContext.Provider>
  );
};
