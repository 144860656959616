import React from 'react';

import {Grid, Typography} from '@mui/material';
import {TextField} from 'shared/components';

export const AdditionalComments: React.FC = () => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" textTransform="uppercase">
          Comentários adicionais
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="commentary"
          label="Comentários adicionais"
          multiline
          rows={4}
        />
      </Grid>
    </>
  );
};
