import React, {useCallback, useEffect} from 'react';

import {MenuOpenRounded, MenuRounded} from '@mui/icons-material';
import {
  // Avatar,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {useTitle} from 'shared/hooks';

import {MuiAppBar} from '../styles';

interface IProps {
  drawerOpen: boolean;
  buttonActive: boolean;
  handleDrawerOpen(): void;
  setButtonActive(value: boolean): void;
}

export const AppBar: React.FC<IProps> = ({
  drawerOpen,
  buttonActive,
  setButtonActive,
  handleDrawerOpen,
}) => {
  const {title} = useTitle();
  // const {user} = useAuth();
  const theme = useTheme();
  const matches = useMediaQuery('(min-width:769px)');

  const handleOpenMenu = useCallback(() => {
    handleDrawerOpen();

    setButtonActive(!buttonActive);
  }, [buttonActive, handleDrawerOpen, setButtonActive]);

  useEffect(() => {
    if (!drawerOpen && buttonActive) setButtonActive(false);
  }, [buttonActive, drawerOpen, setButtonActive]);

  return (
    <MuiAppBar position="fixed" open={drawerOpen}>
      <Grid container>
        <Grid item xs={6}>
          <List>
            <ListItem>
              <ListItemIcon>
                <IconButton
                  size="small"
                  onClick={handleOpenMenu}
                  sx={{
                    marginLeft: theme.spacing(0.5),
                    backgroundColor: buttonActive
                      ? theme.palette.divider
                      : 'transparent',
                  }}>
                  {buttonActive ? (
                    <MenuOpenRounded
                      sx={{color: theme.palette.secondary.contrastText}}
                    />
                  ) : (
                    <MenuRounded
                      sx={{color: theme.palette.secondary.contrastText}}
                    />
                  )}
                </IconButton>
              </ListItemIcon>

              {matches && (
                <Box ml={4} mr={2} height={16} width="1px" bgcolor="white" />
              )}

              {/* <img src={logoRet} alt="GVCentris" height={32} /> */}
              <Typography variant="h6" sx={{marginLeft: theme.spacing(2)}}>
                SODOMA
              </Typography>

              {matches && (
                <Typography variant="h6" sx={{marginLeft: theme.spacing(2)}}>
                  {title}
                </Typography>
              )}
            </ListItem>
          </List>
        </Grid>

        <Grid
          item
          xs={6}
          component={Box}
          height={66}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
          paddingRight={3}>
          <Box ml={1} height={16} width="1px" bgcolor="white" />

          {/* <Box gap={2} display="flex" alignItems="center">
            {user?.imageUrl && user.name ? (
              <Avatar alt={user.name} src={user?.imageUrl} />
            ) : (
              <Avatar>
                {user
                  ? `${user.name?.split(' ')[0][0]}
              ${user.name?.split(' ')[1][0]}`
                  : '??'}
              </Avatar>
            )}
            {matches && (
              <Typography>
                Olá, <strong>{user.name}</strong>
              </Typography>
            )}
          </Box> */}
        </Grid>
      </Grid>
    </MuiAppBar>
  );
};
