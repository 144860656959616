import React, {useCallback} from 'react';
import {BrowserRouter} from 'react-router-dom';

import {SplashScreen} from 'shared/common';
import {useAuth} from 'shared/hooks';

import {AdminRoutes} from './admin.routes';
import {ProviderRoutes} from './provider.routes';

export const Routes: React.FC = () => {
  const {authenticated, isAdmin, loading} = useAuth();

  const Routes = useCallback(() => {
    if (loading) return <SplashScreen />;
    if (authenticated && isAdmin) return <AdminRoutes />;
    return <ProviderRoutes />;
  }, [authenticated, isAdmin, loading]);

  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};
