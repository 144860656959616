import React, {useCallback, useState} from 'react';

import {Grid, Stack} from '@mui/material';
import {Button, Form, Modal} from 'shared/components';
import {IShipping, SHIPPING} from 'shared/constants';
import {useAuth, useCart, useOrder} from 'shared/hooks';
import {feedback} from 'shared/services/alertService';
import {orderService} from 'shared/services/api/orderService';

import {
  AdditionalComments,
  CardProducts,
  ContactDetails,
  Delivery,
  DeliverySummary,
} from './Modules';

interface IProps {
  openModal: boolean;
  onClose: () => void;
}

export const CheckOut: React.FC<IProps> = ({openModal, onClose}) => {
  const [step, setStep] = useState(0);
  const [shippingInfo, setShippingInfo] = useState<IShipping>({} as IShipping);

  const {user, authenticated} = useAuth();
  const {address, order, setAddress, setOrder} = useOrder();
  const {cartItems, totalPrice} = useCart();

  const handleOnSubmit = useCallback(
    (model: any) => {
      const shipping = SHIPPING.find(
        (item) => item.shippingId === model.shipping,
      );
      if (!shipping) {
        feedback('Selecione um tipo de entrega', 'error');
        setStep(0);
        return;
      }

      setShippingInfo(shipping);

      if (step === 0) {
        setAddress((state) => ({...state, ...model}));
        setOrder((state) => ({...state, ...model, items: cartItems}));

        setStep(1);
      }

      if (step === 1) {
        setOrder((state) => ({...state, commentary: model.commentary}));

        orderService.checkOut({
          full_name: model.full_name,
          order,
          address,
          user,
          totalPrice,
          shipping,
        });
      }
    },
    [step, setAddress, setOrder, cartItems, order, address, user, totalPrice],
  );

  return (
    <Modal opened={openModal} onClose={onClose} maxWidth="xl" title="SODOMA">
      <Form
        onSubmit={handleOnSubmit}
        initialData={{...user, ...order, ...address}}>
        <Grid container spacing={2}>
          {step === 0 && (
            <Grid item xs={12} md={8}>
              <Stack
                sx={{
                  overflow: 'auto',
                  height: '80vh',
                  pr: {md: 2, xs: 0},
                }}>
                <Grid container spacing={2}>
                  {authenticated && <ContactDetails />}

                  <Delivery />
                </Grid>
              </Stack>
            </Grid>
          )}

          {step === 1 && (
            <Grid item xs={12} md={8}>
              <Stack
                sx={{
                  overflow: 'auto',
                  height: '80vh',
                  pr: {md: 2, xs: 0},
                }}>
                <Grid container spacing={2}>
                  <DeliverySummary
                    setStep={setStep}
                    shippingInfo={shippingInfo}
                  />

                  <AdditionalComments />

                  <Grid item xs={12}>
                    <Stack
                      direction={{sx: 'column', sm: 'row'}}
                      justifyContent="flex-end">
                      <Button
                        type="submit"
                        label="Fazer pedido"
                        color="primary"
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          )}

          <Grid item sx={{display: {md: 'block', xs: 'none'}}} md={4}>
            <CardProducts />
          </Grid>
        </Grid>
      </Form>
    </Modal>
  );
};
