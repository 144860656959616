import React, {useRef, useState} from 'react';

import {Grid, Paper, Skeleton, Stack} from '@mui/material';
import {Carousel} from 'shared/components';
import {IImage} from 'shared/services/api/imagesService';

interface IProps {
  images: IImage[];
  isLoading: boolean;
}

export const ProductImages: React.FC<IProps> = ({
  images,
  isLoading = false,
}) => {
  const [indexImage, setIndexImage] = useState(0);

  const skeletonRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Grid
        container
        sx={{
          display: {xs: 'flex', md: 'none'},
        }}>
        <Grid item xs={12}>
          {!isLoading ? (
            <Carousel>
              {images?.map((image) => (
                <Paper
                  component="img"
                  sx={{p: 0.1}}
                  key={image.id}
                  src={image?.path}
                  alt="Product"
                  width="100%"
                />
              ))}
            </Carousel>
          ) : (
            <Skeleton
              component={Paper}
              ref={skeletonRef}
              variant="rectangular"
              sx={{p: 0.1}}
              width="100%"
              height={skeletonRef.current?.clientWidth}
            />
          )}
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          display: {xs: 'none', md: 'flex'},
        }}>
        <Grid item xs={1.5}>
          <Stack height="100%" spacing={1}>
            {!isLoading
              ? images?.map((image, index) => (
                  <Paper
                    component="img"
                    sx={{cursor: 'pointer'}}
                    key={image.id}
                    src={image?.path}
                    alt="Product"
                    onClick={() => setIndexImage(index)}
                  />
                ))
              : Array.from(Array(4)).map((_, index) => (
                  <Skeleton
                    component={Paper}
                    key={index}
                    variant="rectangular"
                    height={82.33}
                    width={82.33}
                  />
                ))}
          </Stack>
        </Grid>

        <Grid item xs={10.5} pl={2}>
          {!isLoading ? (
            <Paper
              component="img"
              src={images[indexImage]?.path}
              alt="Product"
              width="100%"
            />
          ) : (
            <Skeleton
              component={Paper}
              variant="rectangular"
              height={560.31}
              width={560.31}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
