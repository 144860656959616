import {useContext} from 'react';

import {OrderContext, OrderProvider, IContextOrder} from 'shared/context/Order';

function useOrder(): IContextOrder {
  const context = useContext(OrderContext);

  if (!context) {
    throw new Error('useOrder must be used within an OrderProvider');
  }

  return context;
}

export {OrderProvider, useOrder};
