import React from 'react';

import {Grid, Stack, Typography} from '@mui/material';

interface IProps {
  title: string;
  children?: React.ReactNode;
}

export const Title: React.FC<IProps> = ({title, children}) => {
  return (
    <Grid item xs={12}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" fontWeight={600} textTransform="uppercase">
          {title}
        </Typography>

        {children}
      </Stack>
    </Grid>
  );
};
