import {createTheme} from '@mui/material';
import {ptBR} from '@mui/material/locale';

export const dark = createTheme(
  {
    palette: {
      mode: 'dark',
      primary: {
        main: '#7e221b',
        light: '#c25a51',
        dark: '#451d1c',
        contrastText: '#fff',
      },
      secondary: {
        main: '#332d2e',
        light: '#5e5758',
        dark: '#1d191a',
        contrastText: '#fff',
      },
      background: {
        default: '#f1e7df',
        paper: '#e6cdad',
      },
    },
    typography: {
      fontFamily: "'Roboto Mono', monospace",
    },
  },
  ptBR,
);
