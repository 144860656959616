import React, {useState, createContext, useCallback, useEffect} from 'react';

export interface IContextTitle {
  setTitle(value: string): void;
  title: string;
}

export const TitleContext = createContext<IContextTitle>({} as IContextTitle);

interface IProps {
  children: React.ReactNode;
}

export const TitleProvider: React.FC<IProps> = ({children}) => {
  const [title, setTitle] = useState('');

  const handleSetTitle = useCallback((value: string) => {
    document.title = `${value ? `${value} - ` : ''}SODOMA`;
    setTitle(value);
  }, []);

  useEffect(() => {
    handleSetTitle('');
  }, [handleSetTitle]);

  return (
    <TitleContext.Provider value={{title, setTitle: handleSetTitle}}>
      {children}
    </TitleContext.Provider>
  );
};
