import React from 'react';

import {Stack, Divider, Typography, Card} from '@mui/material';
import {useCart} from 'shared/hooks';
import {moneyMask} from 'shared/utils/mask';

import {CartItem} from './CartItem';

export const CardProducts: React.FC = () => {
  const {cartItems, totalPrice} = useCart();

  return (
    <Card variant="outlined">
      {cartItems.map((item) => (
        <CartItem key={item.id} product={item} />
      ))}

      <Stack padding="0 16px 16px 16px" gap={2}>
        <Divider />

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">Subtotal:</Typography>

          <Typography variant="body2">{moneyMask(totalPrice)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">Frete:</Typography>

          <Typography variant="body2">
            {moneyMask(totalPrice > 250 ? 0 : 25)}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Total:</Typography>

          <Typography variant="h6">
            {moneyMask(totalPrice > 250 ? totalPrice : totalPrice + 25)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
