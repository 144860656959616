import {createTheme} from '@mui/material';
import {ptBR} from '@mui/material/locale';

export const light = createTheme(
  {
    palette: {
      primary: {
        main: '#7e221b',
        light: '#c25a51',
        dark: '#451d1c',
        contrastText: '#f1e7df',
      },
      secondary: {
        main: '#332d2e',
        light: '#5e5758',
        dark: '#1d191a',
        contrastText: '#f1e7df',
      },
      text: {
        // primary: '#e6cdad',
        secondary: '#332d2e',
      },
      common: {
        black: '#332d2e',
        white: '#f1e7df',
      },
      background: {
        default: '#f1e7df',
        paper: '#f1e7df',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: "'Jost', sans-serif",
      h1: {
        fontFamily: "'Marcellus', serif",
        fontWeight: 700,
      },
      h2: {
        fontFamily: "'Marcellus', serif",
        fontWeight: 700,
      },
      h3: {
        fontFamily: "'Marcellus', serif",
        fontWeight: 700,
      },
      h4: {
        fontFamily: "'Marcellus', serif",
        fontWeight: 700,
      },
      h5: {
        fontFamily: "'Marcellus', serif",
        fontWeight: 700,
      },
      h6: {
        fontFamily: "'Marcellus', serif",
        fontWeight: 700,
      },
    },
  },
  ptBR,
);
