import axios from 'axios';

export interface ICep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
}

const getZipConde = async (cep: string): Promise<ICep> => {
  const {data: response} = await axios.get(
    `https://viacep.com.br/ws/${cep.replace(/\D/gim, '').trim()}/json/`,
  );

  return response;
};

export const locationsService = {
  getZipConde,
};
