import {QueryClient} from '@tanstack/react-query';

import {feedback} from '../alertService';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: async (err: any) => {
        feedback(err.response.data.message, 'error');
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      cacheTime: 0,
      retry: 0,
    },
    mutations: {
      onError: async (err: any) => {
        feedback(err.response.data.message, 'error');
      },
    },
  },
});
