import React from 'react';
import MuiCarousel, {CarouselProps} from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

interface IResponsive {
  items: number;
  slidesToSlide: number;
}

interface IProps extends Omit<CarouselProps, 'responsive'> {
  children: React.ReactNode;
  desktopResponsive?: IResponsive;
  tabletResponsive?: IResponsive;
  mobileResponsive?: IResponsive;
}

export const Carousel: React.FC<IProps> = ({
  children,
  desktopResponsive = {items: 4, slidesToSlide: 1},
  tabletResponsive = {items: 2, slidesToSlide: 2},
  mobileResponsive = {items: 1, slidesToSlide: 1},
  ...rest
}) => {
  const responsive = {
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: desktopResponsive.items,
      slidesToSlide: desktopResponsive.slidesToSlide,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: tabletResponsive.items,
      slidesToSlide: tabletResponsive.slidesToSlide,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: mobileResponsive.items,
      slidesToSlide: mobileResponsive.slidesToSlide,
    },
  };

  return (
    <MuiCarousel {...rest} responsive={responsive}>
      {children}
    </MuiCarousel>
  );
};
