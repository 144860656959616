import React from 'react';

import {KeyboardArrowRight} from '@mui/icons-material';
import {Container, IconButton, Stack} from '@mui/material';
import {Form, TextField} from 'shared/components';

export const InputNewLetter: React.FC = () => {
  const handleOnSubmit = (data: any) => {
    // eslint-disable-next-line
    alert(data.email);
  };

  return (
    <Stack pb={2} pt={4}>
      <Container maxWidth="md">
        <Form onSubmit={handleOnSubmit}>
          <TextField
            name="email"
            placeholder="Digite seu e-mail aqui..."
            inputProps={{
              style: {
                color: '#f1e7df',
              },
            }}
            sx={{
              '& .MuiInput': {
                '& input': {
                  '&::placeholder': {
                    color: 'common.white',
                  },
                },
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'common.white',
                },
                '&:hover fieldset': {
                  borderColor: 'common.white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'common.white',
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <IconButton type="submit">
                  <KeyboardArrowRight sx={{color: 'common.white'}} />
                </IconButton>
              ),
            }}
          />
        </Form>
      </Container>
    </Stack>
  );
};
