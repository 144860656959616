import React, {useState, createContext} from 'react';

export interface IMenuOptions {
  id: string;
  icon: string;
  label: string;
  subMenu?: IMenuOptions[];
  path?: string;
}

export interface IContextDrawer {
  setDrawerOpen(value: boolean): void;
  drawerOpen: boolean;
  menuOptions: IMenuOptions[];
}

export const DrawerContext = createContext<IContextDrawer>(
  {} as IContextDrawer,
);

const menuOptions = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    icon: 'home',
    path: '/',
  },
  {
    id: 'genres',
    label: 'Gêneros',
    icon: 'wc',
    path: '/genres',
  },
  {
    id: 'categories',
    label: 'Categorias',
    icon: 'category',
    path: '/categories',
  },
  {
    id: 'products',
    label: 'Produtos',
    icon: 'shopping_bag',
    path: '/products',
  },
  {
    id: 'users',
    label: 'Usuários',
    icon: 'people',
    path: '/users',
  },
];

interface IProps {
  children: React.ReactNode;
}

export const DrawerProvider: React.FC<IProps> = ({children}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <DrawerContext.Provider value={{drawerOpen, setDrawerOpen, menuOptions}}>
      {children}
    </DrawerContext.Provider>
  );
};
