import React, {lazy, Suspense} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

import {SplashScreen} from 'shared/common';
import {PrivateLayout} from 'styles/layout/privateLayout';

const Dashboard = lazy(() => import('pages/admin/Dashboard'));
const Products = lazy(() => import('pages/admin/Products'));
const Users = lazy(() => import('pages/admin/Users'));
const Categories = lazy(() => import('pages/admin/Categories'));
const Genres = lazy(() => import('pages/admin/Genres'));

export const AdminRoutes: React.FC = () => {
  return (
    <PrivateLayout>
      <Suspense fallback={<SplashScreen />}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/products" element={<Products />} />
          <Route path="/users" element={<Users />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/genres" element={<Genres />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </PrivateLayout>
  );
};
