import React from 'react';

import {
  DialogActions,
  DialogContent,
  Dialog,
  Stack,
  SxProps,
  Theme,
  DialogTitle,
} from '@mui/material';

import {Button} from '../Button';

interface IProps {
  loading?: boolean;
  disabled?: boolean;
  opened: boolean;
  onClose?(): void;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  title?: string;
  labelCloseButton?: string;
  labelSaveButton?: string;
  onClick?(): void;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

export const Modal: React.FC<IProps> = ({
  opened,
  loading = false,
  disabled = false,
  onClose,
  maxWidth,
  title,
  children,
  labelCloseButton,
  labelSaveButton,
  onClick,
  sx,
}) => {
  return (
    <Dialog
      open={opened}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth
      sx={sx}>
      {title && (
        <DialogTitle variant="h4" color="primary.main">
          {title}
        </DialogTitle>
      )}
      <DialogContent>
        <Stack pt={1}>{children}</Stack>
      </DialogContent>
      {(labelSaveButton || labelCloseButton) && (
        <DialogActions
          style={{
            padding: '8px 24px 32px',
          }}>
          <Stack direction="row" spacing={3}>
            {labelCloseButton && (
              <Button
                variant="outlined"
                disabled={loading}
                label={labelCloseButton}
                onClick={onClose}
              />
            )}
            {labelSaveButton && (
              <Button
                label={labelSaveButton}
                onClick={onClick}
                disabled={loading || disabled}
                loading={loading}
              />
            )}
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
};
