import React from 'react';
import {useNavigate} from 'react-router-dom';

import {Card, Paper, Rating, Stack, Typography} from '@mui/material';
import placeHolder from 'shared/assets/placeholder.jpeg';
import {Button} from 'shared/components';
import {useCart} from 'shared/hooks';
import {IProduct} from 'shared/services/api/productsService';
import {moneyMask} from 'shared/utils/mask';

interface IProps {
  product: IProduct;
}

export const ProductCard: React.FC<IProps> = ({product}) => {
  const navigate = useNavigate();
  const {addItem} = useCart();

  const handleClick = () => {
    navigate(`/product/${product.id}`);
  };

  const handleAddItem = () => {
    addItem({
      id: product.id,
      name: product.name,
      image: product?.images[0]?.path || '',
      price: product.price,
      quantity: 1,
    });
  };

  return (
    <Stack m={1.5} flex={1}>
      <Stack
        component={Card}
        flex={1}
        height="100%"
        variant="outlined"
        gap={1.5}
        mb={1}
        p={2}
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          opacity: 0.9,
          transition: 'all 0.3s',
          '&:hover': {
            boxShadow: 1,
            opacity: 1,
            pb: 1.5,
          },
        }}>
        <Paper
          component="img"
          src={product.images[0]?.path || placeHolder}
          alt={product.name}
        />

        <Typography variant="body2" fontSize={12} align="center" height={30}>
          {product.name}
        </Typography>

        <Typography fontSize={16} align="center" fontWeight={600}>
          {moneyMask(product.price)}
        </Typography>

        <Typography variant="caption" fontSize={12} align="center">
          3x de {moneyMask(product.price / 3)}
        </Typography>

        <Stack alignItems="center">
          <Rating name="read-only" value={product.rating} readOnly />
          <Typography fontSize={12} align="center" fontWeight={600}>
            ({product.rating})
          </Typography>
        </Stack>
      </Stack>

      <Button
        label="Adicionar ao carrinho"
        onClick={handleAddItem}
        fullWidth
        sx={{
          boxShadow: 'none',
          transition: 'all 0.3s',
          ':hover': {
            boxShadow: 1,
          },
        }}
      />
    </Stack>
  );
};
