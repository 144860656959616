import React, {useState} from 'react';

import {Close, Info} from '@mui/icons-material';
import {Chip, IconButton, Stack, Typography} from '@mui/material';
import {Button} from 'shared/components';
import {useAuth, useCart} from 'shared/hooks';
import {moneyMask} from 'shared/utils/mask';

import {CheckOut} from '../CheckOut';
import {CartItem} from './CartItem';
import {ShippingStatus} from './ShippingStatus';

interface IProps {
  onClose: () => void;
  onLogin: () => void;
}

export const Cart: React.FC<IProps> = ({onClose, onLogin}) => {
  const [openModal, setOpenModal] = useState(false);

  const {cartItems, totalPrice} = useCart();
  const {authenticated} = useAuth();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Stack justifyContent="space-between" height="100vh">
      <Stack>
        <Stack
          borderRadius={0.8}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          bgcolor="secondary.dark"
          pl={2}
          paddingY={0.5}>
          <Typography
            variant="h6"
            fontSize={20}
            fontWeight={600}
            color="common.white"
            textTransform="uppercase">
            Carrinho de compras
          </Typography>

          <IconButton onClick={onClose}>
            <Close sx={{color: 'common.white'}} />
          </IconButton>
        </Stack>

        {cartItems.map((item) => (
          <CartItem key={item.id} product={item} />
        ))}

        <ShippingStatus totalPrice={totalPrice} />

        {cartItems.length === 0 && (
          <Stack alignItems="center" justifyContent="center" p={4}>
            <Chip icon={<Info />} label="Seu carrinho de compras está vazio" />
          </Stack>
        )}
      </Stack>

      {cartItems.length > 0 && (
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            p={3}>
            <Typography fontSize={32} fontWeight={800}>
              Total:
            </Typography>

            <Stack>
              <Typography fontSize={24} fontWeight={800}>
                {moneyMask(totalPrice)}
              </Typography>

              <Typography fontSize={12} fontWeight={800}>
                Ou até 3x de {moneyMask(totalPrice / 3)}
              </Typography>
            </Stack>
          </Stack>

          <Button
            label="Continuar comprando"
            onClick={onClose}
            fullWidth
            variant="text"
            color="secondary"
            size="large"
          />

          <Button
            label={authenticated ? 'Finalizar compra' : 'Fazer login'}
            onClick={authenticated ? handleOpenModal : onLogin}
            fullWidth
            color="primary"
            size="large"
          />

          {!authenticated && (
            <Button
              label="Finalizar compra sem fazer login"
              onClick={handleOpenModal}
              fullWidth
              variant="text"
              color="secondary"
              size="large"
            />
          )}
        </Stack>
      )}

      <CheckOut openModal={openModal} onClose={handleCloseModal} />
    </Stack>
  );
};
