export interface IShipping {
  name: string;
  totalPrice: number;
  shippingId: string;
  shippingDate: string;
  shippingPrice: number;
}

export const SHIPPING: IShipping[] = [
  {
    name: 'Correios',
    totalPrice: 0,
    shippingId: '1',
    shippingDate: '4 dias úteis',
    shippingPrice: 0,
  },
  {
    name: 'Transportadora',
    totalPrice: 0,
    shippingId: '2',
    shippingDate: '2 dias úteis',
    shippingPrice: 0,
  },
  {
    name: 'Retirar na loja',
    totalPrice: 0,
    shippingId: '3',
    shippingDate: '1 dia útil',
    shippingPrice: 0,
  },
];

export const CONTACTS = {
  email: 'sodoma.sexshops.art.br@gmail.com',
  instagram: 'https://www.instagram.com/sodoma.sexshopp',
  phone: '55996043571',
  whatsapp: '55996043571',
};
