import React from 'react';

import {Grid, Stack, Typography} from '@mui/material';
import bgIntro from 'shared/assets/ilustracao_areia.png';
import {Button, Modal} from 'shared/components';

interface IProps {
  openModal: boolean;
  onClose: () => void;
}

export const Intro: React.FC<IProps> = ({openModal, onClose}) => {
  const handleExitSite = () => {
    window.location.href = 'https://www.google.com.br';
  };

  return (
    <Modal opened={openModal} onClose={onClose} maxWidth="xs">
      <Stack
        sx={{
          mt: -3.5,
          ml: -3,
          mr: -3,
          mb: -2.5,
        }}>
        <Stack
          justifyContent="center"
          alignItems="center"
          bgcolor="#e6cdad"
          height="100%"
          pt={{md: 12, xs: 6}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                align="center"
                lineHeight={1.3}
                sx={{
                  color: 'primary.main',
                  fontSize: {xs: 24, md: 28},
                }}>
                Bem-vind@ <br /> à cidade dos prazeres
              </Typography>

              <Stack
                bgcolor="primary.main"
                borderRadius={50}
                width={{md: 200, xs: 180}}
                height={{md: 200, xs: 180}}
                justifyContent="center"
                alignItems="center"
                p={2}
                mt={{md: 8, xs: 4}}
                ml="auto"
                mr="auto"
                mb={-12}>
                <Stack
                  component="img"
                  src={bgIntro}
                  width={{md: 160, xs: 140}}
                  mt={-2}
                />
              </Stack>

              <Stack
                bgcolor="primary.main"
                pt={14}
                pl={{md: 8, xs: 4}}
                pr={{md: 8, xs: 4}}
                pb={{md: 4, xs: 3}}>
                <Button
                  sx={{
                    mt: 4,
                    bgcolor: '#e6cdad',
                    fontSize: {xs: 16, md: 24},
                    ':hover': {bgcolor: '#e6cdad88'},
                  }}
                  fullWidth
                  size="large">
                  <Typography
                    variant="h5"
                    color="primary.main"
                    sx={{
                      fontSize: 18,
                    }}
                    onClick={onClose}
                    textTransform="uppercase">
                    Entrar
                  </Typography>
                </Button>

                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    mt: 1,
                    borderColor: '#e6cdad',
                    borderWidth: 2,
                    ':hover': {
                      bgcolor: '#e6cdad88',
                      borderColor: '#e6cdad88',
                      borderWidth: 2,
                    },
                  }}>
                  <Typography
                    variant="h6"
                    color="#e6cdad"
                    onClick={handleExitSite}
                    sx={{
                      fontSize: 16,
                    }}
                    textTransform="uppercase">
                    Sair
                  </Typography>
                </Button>

                <Stack
                  direction="row"
                  border={2}
                  borderRadius={2}
                  borderColor={'#e6cdad'}
                  justifyContent="center"
                  alignItems="center"
                  mt={6}
                  marginX={{md: 8, xs: 4}}
                  gap={2}>
                  <Typography
                    variant="h2"
                    sx={{
                      color: '#e6cdad',
                      fontSize: 32,
                    }}>
                    +18
                  </Typography>

                  <Stack border={1} borderColor={'#e6cdad'} height={60} />

                  <Typography
                    variant="h5"
                    align="center"
                    sx={{
                      color: '#e6cdad',
                      fontSize: {xs: 16, md: 18},
                    }}>
                    Conteúdo <br /> Adulto
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Modal>
  );
};
