import React from 'react';
import {useNavigate} from 'react-router-dom';

import {AccountCircle} from '@mui/icons-material';
import {IconButton, Menu, MenuItem, Tooltip} from '@mui/material';
import {useAuth} from 'shared/hooks';

interface IProps {
  onLogin(): void;
  onRegister(): void;
}

export const ProfileMenu: React.FC<IProps> = ({onLogin, onRegister}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {authenticated, logout} = useAuth();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  const handleLogin = () => {
    onLogin();
    handleClose();
  };

  const handleRegister = () => {
    onRegister();
    handleClose();
  };

  const handleToProfile = () => {
    navigate('profile');
    handleClose();
  };

  return (
    <>
      <Tooltip title="">
        <IconButton
          id="profile-menu"
          color="inherit"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <AccountCircle />
        </IconButton>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'profile-menu',
        }}>
        {authenticated ? (
          <span>
            <MenuItem onClick={handleToProfile}>Minha conta</MenuItem>
            <MenuItem onClick={handleLogout}>Sair</MenuItem>
          </span>
        ) : (
          <span>
            <MenuItem onClick={handleLogin}>Entrar</MenuItem>
            <MenuItem onClick={handleRegister}>Registrar-se</MenuItem>
          </span>
        )}
      </Menu>
    </>
  );
};
