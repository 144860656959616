import React from 'react';

import {Paper, Skeleton, Stack} from '@mui/material';

export const SkeletonProductCard: React.FC = () => {
  return (
    <Stack m={1.5}>
      <Skeleton
        component={Paper}
        sx={{mb: 1, p: 2}}
        variant="rectangular"
        width={264}
        height={432.22}
      />

      <Skeleton component={Paper} height={36} />
    </Stack>
  );
};
