import {api} from '../axios';

export interface ICategory {
  id: number;
  name: string;
  slug: string;
  description: string;
}

const index = async (): Promise<ICategory[]> => {
  const {data: response} = await api.get<ICategory[]>('/categories');

  return response;
};

const show = async (slug: string): Promise<ICategory> => {
  const {data: response} = await api.get<ICategory>(`/categories/${slug}`);

  return response;
};

const store = async (data: Partial<ICategory>): Promise<ICategory> => {
  const {data: response} = await api.post<ICategory>('/categories', data);

  return response;
};

const update = async (data: Partial<ICategory>): Promise<ICategory> => {
  const {data: response} = await api.put(`/categories/${data.id}`, data);

  return response;
};

const destroy = async (id: number): Promise<void> => {
  await api.delete(`/categories/${id}`);
};

export const categoriesService = {index, show, store, update, destroy};
