import '../index.css';
import React, {Fragment, useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';

import {
  MenuRounded,
  ShoppingCartRounded,
  SearchRounded,
  Instagram,
  Email,
  Close,
  Phone,
  WhatsApp,
} from '@mui/icons-material';
import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import logoWhite from 'shared/assets/logo-horizontal-branco.png';
import {ModalLogin, ModalRegister} from 'shared/common';
import {CONTACTS} from 'shared/constants';
import {useCart} from 'shared/hooks';
import {categoriesService} from 'shared/services/api/categoriesService';
import {formatPhoneNumber} from 'shared/utils/mask';

import {AlertComponent} from '../Alert';
import {Cart} from './Cart';
import {FooterExternalLink} from './FooterExternalLink';
import {FooterLink} from './FooterLink';
import {HideOnScroll} from './HideOnScroll';
import {Intro} from './Intro';
import {ProfileMenu} from './ProfileMenu';

interface IProps {
  window?: () => Window;
  children: React.ReactNode;
}

export const PublicLayout: React.FC<IProps> = ({window, children}) => {
  const [cartOpen, setCartOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openModalLogin, setOpenModalLogin] = useState(false);
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [openIntro, setOpenIntro] = useState(true);

  const navigate = useNavigate();
  const {quantity} = useCart();
  const {data: categories} = useQuery(['categories'], categoriesService.index);

  const handleNavigate = (slug: string) => {
    navigate(`/products/${slug}/category`);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((state) => !state);
  };

  const handleDrawerCartToggle = () => {
    setCartOpen((state) => !state);
  };

  const handleOpenModalLogin = () => {
    setOpenModalLogin(true);
  };

  const handleOpenModalRegister = () => {
    setOpenModalRegister(true);
  };

  const handleCloseModals = () => {
    setOpenModalLogin(false);
    setOpenModalRegister(false);
    setOpenIntro(false);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <Stack bgcolor="gray" flexDirection="row" paddingY={0.4} pl={1} gap={0.4}>
        <IconButton size="small" href={CONTACTS.instagram} target="_blank">
          <Instagram fontSize="small" />
        </IconButton>

        <IconButton
          size="small"
          href={`mailto:${CONTACTS.email}`}
          target="_blank">
          <Email fontSize="small" />
        </IconButton>
      </Stack>

      <Divider />

      <List>
        <ListItem disablePadding sx={{mt: 0}}>
          <ListItemButton sx={{pt: 0, pl: 1.5}}>
            <ListItemIcon>
              <Close fontSize="small" />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>

        <Divider />

        {categories?.map((item) => (
          <Fragment key={item.id}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleNavigate(item.slug)}>
                <Typography variant="h6" textTransform="uppercase">
                  {item.name}
                </Typography>
              </ListItemButton>
            </ListItem>

            <Divider />
          </Fragment>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{display: 'flex'}}>
      <HideOnScroll>
        <AppBar component="nav">
          <Stack
            display={{xs: 'none', md: 'flex'}}
            flexDirection="row"
            paddingY={0.4}
            bgcolor="secondary.dark"
            gap={0.4}
            pl={1}>
            <IconButton size="small" href={CONTACTS.instagram} target="_blank">
              <Instagram fontSize="small" color="primary" />
            </IconButton>

            <IconButton
              size="small"
              href={`mailto:${CONTACTS.email}`}
              target="_blank">
              <Email fontSize="small" color="primary" />
            </IconButton>
          </Stack>

          <Toolbar>
            <Stack
              paddingY={1}
              spacing={1}
              flex={1}
              display={{xs: 'flex', md: 'none'}}>
              <Stack
                flex={1}
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{display: {md: 'none'}}}>
                  <MenuRounded sx={{fontSize: 32}} />
                </IconButton>

                <Link to="/">
                  <img src={logoWhite} alt="logo SODOMA" height={40} />
                </Link>

                <Stack direction="row">
                  <ProfileMenu
                    onLogin={handleOpenModalLogin}
                    onRegister={handleOpenModalRegister}
                  />

                  <IconButton
                    color="inherit"
                    sx={{ml: 1}}
                    onClick={handleDrawerCartToggle}>
                    <Badge badgeContent={quantity} color="secondary">
                      <ShoppingCartRounded />
                    </Badge>
                  </IconButton>
                </Stack>
              </Stack>

              <Stack paddingY={1}>
                <TextField
                  placeholder="O que você está buscando"
                  size="small"
                  inputProps={{
                    style: {
                      color: '#f1e7df',
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'common.white',
                      },
                      '&:hover fieldset': {
                        borderColor: 'common.white',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'common.white',
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: <SearchRounded sx={{color: 'white'}} />,
                  }}
                />
              </Stack>
            </Stack>

            <Stack display={{xs: 'none', md: 'flex'}} flex={1}>
              <Grid container alignItems="center" spacing={2} paddingY={2}>
                <Grid item md={3}>
                  <Stack flexDirection="row">
                    <Link to="/">
                      <img src={logoWhite} alt="logo SODOMA" height={56} />
                    </Link>
                  </Stack>
                </Grid>

                <Grid item md={6}>
                  <TextField
                    placeholder="O que você está buscando ..."
                    size="small"
                    fullWidth
                    inputProps={{
                      style: {
                        color: '#f1e7df',
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'common.white',
                        },
                        '&:hover fieldset': {
                          borderColor: 'common.white',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'common.white',
                        },
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <SearchRounded sx={{color: 'common.white'}} />
                      ),
                    }}
                  />
                </Grid>

                <Grid item md={3}>
                  <Stack flexDirection="row" justifyContent="flex-end">
                    <ProfileMenu
                      onLogin={handleOpenModalLogin}
                      onRegister={handleOpenModalRegister}
                    />

                    <IconButton
                      sx={{ml: 1}}
                      color="inherit"
                      onClick={handleDrawerCartToggle}>
                      <Badge badgeContent={quantity} color="secondary">
                        <ShoppingCartRounded />
                      </Badge>
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Toolbar>

          <Stack flex={1} sx={{display: {xs: 'none', md: 'flex'}}}>
            <Divider />

            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              p={0.5}
              gap={0.5}>
              {categories?.map((item) => (
                <Button
                  key={item.id}
                  sx={{color: '#fff'}}
                  onClick={() => handleNavigate(item.slug)}>
                  <Typography variant="button" textTransform="capitalize">
                    {item.name}
                  </Typography>
                </Button>
              ))}
            </Stack>
          </Stack>
        </AppBar>
      </HideOnScroll>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: {xs: 'block', md: 'none'},
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '80vw',
            },
          }}>
          {drawer}
        </Drawer>
      </Box>

      <Box>
        <Drawer
          container={container}
          variant="temporary"
          open={cartOpen}
          anchor="right"
          onClose={handleDrawerCartToggle}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: {xs: '100vw', md: 500},
            },
          }}>
          <Cart
            onClose={handleDrawerCartToggle}
            onLogin={handleOpenModalLogin}
          />
        </Drawer>
      </Box>

      <Grid container>
        <Box component="main" sx={{pt: {xs: 16.4, md: 20.55, width: '100vw'}}}>
          {children}
        </Box>

        <Grid item xs={12} mt={8}>
          <Stack spacing={4} bgcolor="#e6cdad" boxShadow={6}>
            <Stack>
              <Container maxWidth="lg">
                <Grid container spacing={2} pt={4}>
                  <Grid item xs={12} md={3}>
                    <Stack spacing={2}>
                      <Typography variant="h6" textTransform="uppercase">
                        Departamentos
                      </Typography>

                      {categories?.map((item) => (
                        <FooterLink
                          key={item.id}
                          text={item.name}
                          onClick={() => handleNavigate(item.slug)}
                        />
                      ))}
                    </Stack>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Stack spacing={2}>
                      <Typography variant="h6" textTransform="uppercase">
                        Informações
                      </Typography>

                      <FooterLink
                        text="Quem Somos"
                        onClick={() => navigate('about')}
                      />

                      <FooterLink
                        text="Trocas e Devoluções"
                        onClick={() => navigate('exchanges-and-returns')}
                      />

                      <FooterLink
                        text="Política de Privacidade"
                        onClick={() => navigate('privacy-policy')}
                      />

                      <FooterLink
                        text="Termos e Condições"
                        onClick={() => navigate('terms')}
                      />

                      <FooterLink
                        text="Missão, Visão e Valores"
                        onClick={() => navigate('vision')}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Stack spacing={2}>
                      <Typography variant="h6" textTransform="uppercase">
                        Entre em contato
                      </Typography>

                      <FooterExternalLink
                        icon={<Phone />}
                        text={formatPhoneNumber(CONTACTS.phone)}
                        link={`tel:${CONTACTS.phone}`}
                      />

                      <FooterExternalLink
                        icon={<WhatsApp />}
                        text={formatPhoneNumber(CONTACTS.phone)}
                        link={`https://wa.me/${CONTACTS.phone}`}
                      />

                      <FooterExternalLink
                        icon={<Email />}
                        text="sodoma.sexshops.art.br@gmail.com"
                        link={`mailto:${CONTACTS.email}`}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Stack direction="row">
                      <IconButton href={CONTACTS.instagram} target="_blank">
                        <Instagram />
                      </IconButton>

                      <IconButton
                        href={`mailto:${CONTACTS.email}`}
                        target="_blank">
                        <Email />
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Container>
            </Stack>

            <Stack bgcolor="secondary.dark">
              <Container maxWidth="lg">
                <Stack
                  direction={{xs: 'column', sm: 'row'}}
                  gap={2}
                  paddingY={2}
                  justifyContent="space-between">
                  <Typography variant="body2" color="common.white">
                    Copyright SODOMA - {new Date().getFullYear()}. Todos os
                    direitos reservados.
                  </Typography>

                  <Typography variant="body2" color="common.white">
                    Criado por <a href="https://guilhermeddc.com.br">GR</a>.
                  </Typography>
                </Stack>
              </Container>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <AlertComponent />

      <ModalLogin
        openModal={openModalLogin}
        onClose={handleCloseModals}
        onRegister={handleOpenModalRegister}
      />

      <ModalRegister
        openModal={openModalRegister}
        onClose={handleCloseModals}
        onLogin={handleOpenModalLogin}
      />

      <Intro openModal={openIntro} onClose={handleCloseModals} />
    </Box>
  );
};
