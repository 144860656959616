export const cpfMask = (str: string): string =>
  str
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

export const cnpjMask = (str: string): string =>
  str
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5');

export const cepMask = (str: string): string =>
  str.replace(/\D/g, '').replace(/(\d{5})(\d{1,2})/, '$1-$2');

export const bankCodeMask = (num: number | string) =>
  Number(num) < 10
    ? `00${num}`
    : Number(num) > 9 && Number(num) < 99
    ? `0${num}`
    : String(num);

export const moneyMask = (value: string | number) =>
  parseFloat(String(value)).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

export const formatPhoneNumber = (phone: string): string => {
  const countryCode = phone.slice(0, 2);
  const areaCode = phone.slice(2, 7);
  const number = phone.slice(7, 11);

  return `(${countryCode}) ${areaCode} ${number}`;
};
