import React from 'react';

import {Typography} from '@mui/material';

interface IProps {
  text: string;
  onClick: () => void;
}

export const FooterLink: React.FC<IProps> = ({text, onClick}) => {
  return (
    <Typography
      color="ButtonText"
      variant="body2"
      fontWeight={500}
      sx={{
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        ':hover': {color: 'primary.light'},
      }}
      onClick={onClick}>
      {text}
    </Typography>
  );
};
