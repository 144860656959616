import React, {useMemo} from 'react';

import {LocalShipping} from '@mui/icons-material';
import {Stack, Typography} from '@mui/material';
import {moneyMask} from 'shared/utils/mask';

interface IProps {
  totalPrice: number;
}

const freeShipping = 250;

export const ShippingStatus: React.FC<IProps> = ({totalPrice}) => {
  const valuePercentage = useMemo(
    () => (totalPrice / freeShipping) * 100,
    [totalPrice],
  );

  return (
    <Stack m={2}>
      <Stack
        position="relative"
        width={{md: 400, xs: 300, sm: 600}}
        margin="32px auto">
        <Stack
          position="absolute"
          width="100%"
          height={8}
          borderRadius={4}
          bgcolor="primary.main"
        />

        <Stack
          position="relative"
          width={`${valuePercentage > 100 ? 100 : valuePercentage}%`}
          height={8}
          borderRadius={4}
          bgcolor="secondary.main"
          justifyContent="center"
          alignItems="flex-end">
          <Stack
            zIndex={1}
            bgcolor="primary.main"
            borderRadius={6}
            p={0.5}
            border="2px solid">
            <LocalShipping />
          </Stack>
        </Stack>
      </Stack>

      {totalPrice <= freeShipping ? (
        <Stack>
          <Typography align="center">
            Faltam {moneyMask(freeShipping - totalPrice)} para você ganhar
          </Typography>
          <Typography variant="h4" align="center" color="secondary.main">
            FRETE GRÁTIS!
          </Typography>
        </Stack>
      ) : (
        <Stack>
          <Typography variant="h4" align="center" color="secondary.main">
            PARABÉNS
          </Typography>
          <Typography align="center">Você ganhou frete grátis</Typography>
        </Stack>
      )}
    </Stack>
  );
};
