import React, {useCallback, useRef} from 'react';

import {Grid, Typography} from '@mui/material';
import {useMutation} from '@tanstack/react-query';
import {FormHandles} from '@unform/core';
import {Button, Checkbox, Form, Modal, TextField} from 'shared/components';
import getValidationErrors from 'shared/helpers/getValidationErrors';
import {useAuth} from 'shared/hooks';
import {feedback} from 'shared/services/alertService';
import {SignIn} from 'shared/services/api/authService';
import * as Yup from 'yup';

interface IProps {
  openModal: boolean;
  onClose: () => void;
  onRegister: () => void;
}

export const ModalLogin: React.FC<IProps> = ({
  onClose,
  openModal,
  onRegister,
}) => {
  const formRef = useRef<FormHandles>(null);
  const {login} = useAuth();

  const handleToRegister = () => {
    onClose();
    onRegister();
  };

  const loginIn = useMutation((data: SignIn) => login(data), {
    onSuccess: () => {
      feedback('Login realizado com sucesso!', 'success');
      onClose();
    },
  });

  const handleOnSubmit = useCallback(
    (model: SignIn) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email é obrigatório')
            .email('Email inválido'),
          password: Yup.string().required('Senha é obrigatória'),
        });

        schema.validateSync(model, {
          abortEarly: false,
        });

        loginIn.mutate(model);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
          }
        }
      }
    },
    [loginIn],
  );

  return (
    <Modal
      opened={openModal}
      onClose={onClose}
      maxWidth="xs"
      loading={loginIn.isLoading}>
      <Form onSubmit={handleOnSubmit} ref={formRef}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2" align="center" fontWeight={600}>
              SODOMA
            </Typography>
          </Grid>

          <Grid item xs={12} />

          <Grid item xs={12}>
            <TextField name="email" label="E-mail" type="email" />
          </Grid>

          <Grid item xs={12}>
            <TextField name="password" label="Senha" type="password" />
          </Grid>

          <Grid item xs={12}>
            <Checkbox name="keepConnected" label="Manter conectado?" />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              label="Entrar"
              size="large"
              loading={loginIn.isLoading}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" align="center">
              Não tem uma conta?
              <Button
                label="Cadastre-se"
                variant="text"
                onClick={handleToRegister}
                loading={loginIn.isLoading}
              />
            </Typography>
          </Grid>
        </Grid>
      </Form>
    </Modal>
  );
};
