import React, {useMemo} from 'react';

import {
  DeleteRounded,
  DoneRounded,
  InfoRounded,
  WarningRounded,
} from '@mui/icons-material';
import {Stack, Typography} from '@mui/material';
import {Modal} from 'shared/components';

interface IProps {
  openModal: boolean;
  onClose: () => void;
  onClick: () => void;
  loading?: boolean;
  title?: string;
  subtitle?: string;
  icon?: React.ReactNode;
  status?: 'success' | 'error' | 'warning' | 'info';
}

export const ModalConfirm: React.FC<IProps> = ({
  openModal,
  onClose,
  onClick,
  loading = false,
  title,
  subtitle,
  icon,
  status,
}) => {
  const dataDialog = useMemo(() => {
    switch (status) {
      case 'warning':
        return {
          title: title || 'Atenção',
          subtitle: subtitle || 'Deseja realmente excluir esse registro?',
          icon: icon || <WarningRounded color="primary" fontSize="large" />,
        };
      case 'info':
        return {
          title: title || 'Informação',
          subtitle: subtitle || 'Deseja realmente excluir esse registro?',
          icon: icon || <InfoRounded color="primary" fontSize="large" />,
        };
      case 'success':
        return {
          title: title || 'Sucesso',
          subtitle: subtitle || 'Deseja realmente excluir esse registro?',
          icon: icon || <DoneRounded color="primary" fontSize="large" />,
        };
      default:
        return {
          title: title || 'Excluir',
          subtitle: subtitle || 'Deseja excluir permanentemente esse registro?',
          icon: icon || <DeleteRounded color="primary" fontSize="large" />,
        };
    }
  }, [icon, status, subtitle, title]);

  return (
    <Modal
      opened={openModal}
      onClose={onClose}
      onClick={onClick}
      labelSaveButton="Sim"
      labelCloseButton="Não"
      maxWidth="xs"
      loading={loading}>
      <Stack display="flex" flexDirection="column">
        <Stack
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={1}>
          <Stack
            style={{transform: 'scale(1.5)'}}
            borderRadius={50}
            height={60}
            width={60}
            display="flex"
            justifyContent="center"
            alignItems="center">
            {dataDialog.icon}
          </Stack>

          <Typography align="center" variant="h6">
            {dataDialog.title}
          </Typography>
          <Typography align="center" variant="body1" color="gray">
            {dataDialog.subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Modal>
  );
};
