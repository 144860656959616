import React, {lazy, Suspense} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

import {SplashScreen} from 'shared/common';
import {useAuth} from 'shared/hooks';
import {PublicLayout} from 'styles/layout/publicLayout';

const Home = lazy(() => import('pages/provider/Home'));
const Profile = lazy(() => import('pages/provider/Profile'));
const CategoryProducts = lazy(() => import('pages/provider/CategoryProducts'));
const GenreProducts = lazy(() => import('pages/provider/GenreProducts'));
const Product = lazy(() => import('pages/provider/Product'));
const About = lazy(() => import('pages/provider/About'));
const Exchanges = lazy(() => import('pages/provider/Exchanges'));
const Privacy = lazy(() => import('pages/provider/Privacy'));
const Terms = lazy(() => import('pages/provider/Terms'));
const Vision = lazy(() => import('pages/provider/Vision'));

export const ProviderRoutes: React.FC = () => {
  const {authenticated} = useAuth();

  return (
    <PublicLayout>
      <Suspense fallback={<SplashScreen />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="exchanges-and-returns" element={<Exchanges />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="vision" element={<Vision />} />

          <Route
            path="products/:slug/category"
            element={<CategoryProducts />}
          />
          <Route path="products/:slug/genre" element={<GenreProducts />} />
          <Route path="product/:id" element={<Product />} />

          <Route
            path="profile"
            element={authenticated ? <Profile /> : <Navigate to="/" />}
          />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </PublicLayout>
  );
};
