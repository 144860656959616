import {api} from '../axios';

export interface SignIn {
  email: string;
  password: string;
  keepConnected?: boolean;
}

export interface User {
  id: number;
  email: string;
  phone: string;
  full_name: string;
  remember_me_token: boolean;
  active: boolean;
  role: string;
}

export interface SignInResponse {
  token: {
    type: string;
    token: string;
    expires_at: Date;
  };
  user: User;
}

const signIn = async (data: SignIn): Promise<SignInResponse> => {
  const {data: response} = await api.post('/users/login', data);

  if (data.keepConnected) {
    localStorage.setItem('sodoma@token', response.token.token);
    localStorage.setItem('sodoma@user', JSON.stringify(response.user));
  } else {
    sessionStorage.setItem('sodoma@token', response.token.token);
    sessionStorage.setItem('sodoma@user', JSON.stringify(response.user));
  }

  return response;
};

export interface SignUp {
  email: string;
  password: string;
  passwordConfirmation?: string;
  phone: string;
  full_name: string;
}

const signUp = async (data: SignUp) => {
  delete data.passwordConfirmation;
  const {data: response} = await api.post('/users', data);

  return response;
};

const signOut = () => {
  localStorage.removeItem('sodoma@token');
  localStorage.removeItem('sodoma@user');
  localStorage.removeItem('sodoma@cartItems');
  sessionStorage.removeItem('sodoma@token');
  sessionStorage.removeItem('sodoma@user');
  sessionStorage.removeItem('sodoma@cartItems');
};

const updateUser = async (data: Partial<User>) => {
  await api.put(`/users/${data.id}`, data);
};

export const authService = {signIn, signUp, signOut, updateUser};
