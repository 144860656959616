import React from 'react';

import {AuthProvider} from './Auth';
import {CartProvider} from './Cart';
import {DrawerProvider} from './Drawer';
import {OrderProvider} from './Order';
import {ThemeProvider} from './Theme';
import {TitleProvider} from './Title';

interface IProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<IProps> = ({children}) => {
  return (
    <TitleProvider>
      <ThemeProvider>
        <AuthProvider>
          <OrderProvider>
            <CartProvider>
              <DrawerProvider>{children}</DrawerProvider>
            </CartProvider>
          </OrderProvider>
        </AuthProvider>
      </ThemeProvider>
    </TitleProvider>
  );
};
