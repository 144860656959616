import React, {createContext, useCallback, useEffect, useState} from 'react';

import {authService, SignIn, User} from 'shared/services/api/authService';
import {api} from 'shared/services/axios';

export interface IAuthContext {
  authenticated: boolean;
  isAdmin: boolean;
  user: User;
  login(data: SignIn): Promise<void>;
  logout(): Promise<void>;
  loading: boolean;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

interface IProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<IProps> = ({children}) => {
  const [user, setUser] = useState<User>({} as User);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    const recoveryUser =
      localStorage.getItem('sodoma@user') ||
      sessionStorage.getItem('sodoma@user');
    const recoveryToken =
      localStorage.getItem('sodoma@token') ||
      sessionStorage.getItem('sodoma@token');

    if (recoveryUser && recoveryToken) {
      setToken(recoveryToken);
      setUser(JSON.parse(recoveryUser) as User);
      setIsAdmin(JSON.parse(recoveryUser).role === process.env.REACT_APP_ADMIN);
    }

    api.defaults.headers.Authorization = `Bearer ${token}`;

    setLoading(false);
  }, [token]);

  const login = useCallback(
    async (data: SignIn) => {
      const response = await authService.signIn(data);

      if (response) {
        setUser(response.user);
        setIsAdmin(response.user.role === process.env.REACT_APP_ADMIN);
        setToken(response.token.token);

        api.defaults.headers.Authorization = `Bearer ${token}`;
      }
    },
    [token],
  );

  const logout = useCallback(async () => {
    authService.signOut();

    setUser({} as User);
    setIsAdmin(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{user, isAdmin, authenticated: !!user.id, login, logout, loading}}>
      {children}
    </AuthContext.Provider>
  );
};
