import React from 'react';

import {Stack, Typography} from '@mui/material';
import {IShipping} from 'shared/constants';
import {moneyMask} from 'shared/utils/mask';

export const MenuItemShipping: React.FC<IShipping> = ({
  name,
  totalPrice,
  shippingDate,
  shippingPrice,
}) => {
  return (
    <Stack flex={1}>
      <Stack
        direction={{sx: 'column', sm: 'row'}}
        gap={{sx: 2, sm: 0}}
        justifyContent="space-between">
        <Typography variant="body2">{name}</Typography>

        <Typography variant="body2">
          {totalPrice > 250
            ? 'Grátis'
            : `${moneyMask((totalPrice - 250) * -1)} para frete grátis`}
        </Typography>
      </Stack>

      <Stack
        direction={{sx: 'column', sm: 'row'}}
        gap={2}
        justifyContent="space-between">
        <Typography variant="body2">Chegada: {shippingDate}</Typography>

        <Typography
          variant="body2"
          sx={{
            textDecorationLine: totalPrice > 250 ? 'line-through' : 'none',
          }}>
          {moneyMask(shippingPrice)}
        </Typography>
      </Stack>
    </Stack>
  );
};
