import React, {
  JSXElementConstructor,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  useTheme,
  Skeleton,
  FormControlLabel,
} from '@mui/material';
import {useField} from '@unform/core';

type TVCheckboxProps = Omit<CheckboxProps, 'checked'> & {
  name: string;
  isLoading?: boolean | undefined;
  skeletonWidth?: number | undefined;
  skeletonHeight?: number | undefined;
  returnValue?(value: boolean): void;
  label:
    | string
    | number
    | ReactElement<any, string | JSXElementConstructor<any>>;
};

export const Checkbox: React.FC<TVCheckboxProps> = ({
  name,
  isLoading,
  skeletonWidth = 20,
  skeletonHeight = 20,
  returnValue,
  label,
  ...rest
}) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const {fieldName, defaultValue, registerField} = useField(name);

  const [isChecked, setIsChecked] = useState<boolean>(defaultValue || false);

  useEffect(() => {
    registerField({
      ref: inputRef.current,
      name: fieldName,
      getValue: () => isChecked,
      setValue: (_, value) => setIsChecked(value),
    });
  }, [fieldName, isChecked, registerField]);

  if (isLoading)
    return (
      <Skeleton
        variant="circular"
        width={skeletonWidth}
        height={skeletonHeight}
        style={{margin: theme.spacing(2)}}
      />
    );

  return (
    <FormControlLabel
      sx={{display: 'inline-block', width: '100%'}}
      control={
        <MuiCheckbox
          {...rest}
          inputRef={inputRef}
          checked={isChecked}
          data-testid={name}
          onChange={(e) => {
            setIsChecked(e.target.checked),
              returnValue && returnValue(e.target.checked);
          }}
        />
      }
      label={label}
    />
  );
};
