import React from 'react';

import {Grid, Typography} from '@mui/material';
import {Checkbox, TextField} from 'shared/components';

export const ContactDetails: React.FC = () => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" textTransform="uppercase">
          Dados do contato
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField name="email" label="E-mail" disabled />

        <Checkbox name="newsletter" label="Receber novidades" />
      </Grid>
    </>
  );
};
