import React, {useCallback, useRef} from 'react';

import {Grid, Typography} from '@mui/material';
import {useMutation} from '@tanstack/react-query';
import {FormHandles} from '@unform/core';
import {Button, Form, Modal, TextField} from 'shared/components';
import getValidationErrors from 'shared/helpers/getValidationErrors';
import {feedback} from 'shared/services/alertService';
import {authService, SignUp} from 'shared/services/api/authService';
import * as Yup from 'yup';

interface IProps {
  openModal: boolean;
  onClose: () => void;
  onLogin: () => void;
}

export const ModalRegister: React.FC<IProps> = ({
  openModal,
  onClose,
  onLogin,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleToLogin = () => {
    onClose();
    onLogin();
  };

  const storeUser = useMutation((data: SignUp) => authService.signUp(data), {
    onSuccess: () => {
      feedback('Conta criada com sucesso!', 'success');
      handleToLogin();
    },
  });

  const handleOnSubmit = useCallback(
    (model: SignUp) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email é obrigatório')
            .email('Email inválido'),
          password: Yup.string().required('Senha é obrigatória'),
          passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Senhas não conferem')
            .required('Confirmação de senha é obrigatória'),
          phone: Yup.string().required('Telefone é obrigatório'),
          full_name: Yup.string().required('Nome completo é obrigatório'),
        });

        schema.validateSync(model, {
          abortEarly: false,
        });

        storeUser.mutate(model);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
          }
        }
      }
    },
    [storeUser],
  );

  return (
    <Modal opened={openModal} onClose={onClose} loading={storeUser.isLoading}>
      <Form onSubmit={handleOnSubmit} ref={formRef}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2" align="center" fontWeight={600}>
              SODOMA
            </Typography>
          </Grid>

          <Grid item xs={12} />

          <Grid item xs={12}>
            <TextField name="full_name" label="Nome completo" />
          </Grid>

          <Grid item xs={12}>
            <TextField name="phone" label="Telefone" />
          </Grid>

          <Grid item xs={12}>
            <TextField name="email" label="E-mail" type="email" />
          </Grid>

          <Grid item xs={12}>
            <TextField name="password" label="Senha" type="password" />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="passwordConfirmation"
              label="Confirmar senha"
              type="password"
            />
          </Grid>

          <Grid item xs={12} />

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              label="Registrar"
              size="large"
              loading={storeUser.isLoading}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" align="center">
              Já possui uma conta?
              <Button
                label="Iniciar sessão"
                variant="text"
                onClick={handleToLogin}
                loading={storeUser.isLoading}
              />
            </Typography>
          </Grid>
        </Grid>
      </Form>
    </Modal>
  );
};
